import React, { useContext, createContext } from "react";

export const validateMapProvider = createContext<any>(null);

const ValidateMapProvider = ({ children }: { children: React.ReactNode }) => {
  const [validateMap, setValidateMap] = React.useState<any>();
  return (
    <validateMapProvider.Provider value={{ validateMap, setValidateMap }}>
      {children}
    </validateMapProvider.Provider>
  );
};

export default ValidateMapProvider;
