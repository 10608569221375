import React from "react";
import { Route, Redirect } from "react-router";
import { useAppContext } from "../../AppContext/App.context";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const [appData]: any = useAppContext();
  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.getItem("loginReq") ? (
          <Component {...`props`} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

export default PrivateRoute;
