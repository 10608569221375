import React, { useState, forwardRef, Suspense, useRef } from "react";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import DateTimeFormatter from "../../utils/DateFormatter";
import headerDropdownService from "../../services/headerDropdown.service";
import useToast from "../Toast/hooks/useToast";
import DateRangePopup from "../DateTimePicker/DateRangePicker";
import classes from "../Header/Header.module.scss";
import headerDropdownClasses from "../../assets/css/HeaderDropdown.module.scss";

const DateRangePopupHeader = forwardRef((props: any, ref: any) => {
  const dateRangeRef: any = useRef();
  const toaster = useToast();
  let startDT: any = new Date();
  startDT = startDT.setDate(
    startDT.getDate() - (props.maxRange ? Number(props.maxRange) - 1 : 6)
  );
  startDT = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
    startDT,
    "YYYY-MM-DD"
  );
  let currentEnDate: any = new Date();
  currentEnDate = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
    currentEnDate,
    "YYYY-MM-DD"
  );
  const [dateRange, setDateRange]: any = useState({
    startDate: startDT,
    endDate: currentEnDate,
  });
  const onChangeDateRange = (range: any) => {
    const dropdownData = {
      type: "dateRange",
      data: { date: range },
    };
    headerDropdownService.emit(dropdownData);
  };
  return (
    <div className={headerDropdownClasses.dropdownOuter}>
      <div className={classes.pickerIcon}>
        <CalendarTodayIcon
          className={classes["datepickerIcon"]}
          style={{ marginBottom: "0px" }}
          //style={{ color: themeColor.iconsColor }}
        />
        <DateRangePopup
          className={"standardRange"}
          start={dateRange.startDate}
          end={dateRange.endDate}
          maxRange={props.maxRange ? props.maxRange : "7"}
          onChange={(start: any, end: any) => {
            let startDate = start;
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            // startDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(startDate);
            let endDate = end || start;
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
            console.log(dateRange.startDate);
            console.log(dateRange.endDate);
            // endDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(endDate);
            let range: any = { startDate, endDate };
            setDateRange(range);
            onChangeDateRange(range);
          }}
        ></DateRangePopup>
      </div>
    </div>
  );
});

export default DateRangePopupHeader;
