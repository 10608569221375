import React, { useContext, createContext } from "react";

export const SideDrawerContext = createContext<any>(null);

const SideDrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const [showSideDrawer, setShowSideDrawer] = React.useState<boolean>(true);
  return (
    <SideDrawerContext.Provider value={{ showSideDrawer, setShowSideDrawer }}>
      {children}
    </SideDrawerContext.Provider>
  );
};

export default SideDrawerProvider;
