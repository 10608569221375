const SigninEndpoints = {
  signin(data: any) {
    return {
      url: "customer/customer/register",
      data: data["reqBody"],
      headers: data["headers"],
    };
  },
};
export default SigninEndpoints;
