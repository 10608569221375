import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router";
// import { useAppContext } from "./AppContext/App.context";
import useLocalStorage from "./hooks/localStorage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

import EditProfile from "./containers/EditProfile/EditProfile";
import PageNotFound from "./components/404/PageNotFound";
// const Register = lazy(() => import("./containers/Signup/Signup"));
import Register from "./containers/Signup/Signup";
import LoginSignUpPage from "./containers/Login/LoginSignUpPage";
// import LoginViaOtp from "./containers/LoginViaOtp/LoginViaOtp";

const LoginViaOtp = lazy(() => import("./containers/LoginViaOtp/LoginViaOtp"));

// import GoDaddy from "./containers/Domains/GoDaddy/GoDaddy";
const Login = lazy(() => import("./containers/Login/Login"));
const ResetPassword = lazy(
  () => import("./containers/ResetPassword/ResetPassword")
);

const JobList = lazy(() => import("./containers/Jobs/JobsList/JobsList"));
const ContactList = lazy(() => import("./containers/SaveContacts/ContactList"));
const AddContact = lazy(() => import("./containers/SaveContacts/AddContact"));

const Analytics = lazy(() => import("./containers/Analytics/Analytics"));

const CreateJob = lazy(() => import("./components/CreateJob/CreateJob"));
// eslint-disable-next-line
const whyDidYouRender = require("@welldone-software/why-did-you-render");

const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/login">
        {useLocalStorage.getItem("isAuthenticated") ? (
          <Redirect to="/jobs/list"></Redirect>
        ) : (
          <LoginSignUpPage />
        )}
      </Route>
      <Route exact path="/">
        <Redirect to="/login"></Redirect>
      </Route>
      <Route exact path="/resetpassword">
        <ResetPassword></ResetPassword>
      </Route>
      <Route exact path="/signup">
        <Register></Register>
      </Route>
      <Route exact path="/checkotp/:countryCode/:phone">
        <LoginViaOtp></LoginViaOtp>
      </Route>
      <PrivateRoute exact path="/jobs/list" component={JobList} />
      <PrivateRoute exact path="/jobs/create" component={CreateJob} />
      <PrivateRoute exact path="/jobs/create/:jobId" component={CreateJob} />
      <PrivateRoute exact path="/analytics" component={Analytics} />
      <PrivateRoute exact path="/contacts" component={ContactList} />
      <PrivateRoute
        exact
        path="/contacts/edit/:contactId"
        component={AddContact}
      />
      <PrivateRoute exact path="/contacts/add" component={AddContact} />
      <PrivateRoute exact path="/editprofile" component={EditProfile} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

AppRoutes.whyDidYouRender = true;

export default AppRoutes;
