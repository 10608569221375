import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  makeStyles,
  Grid,
  Modal,
  Backdrop,
  Fade,
  Typography,
} from "@material-ui/core";
import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  Suspense,
} from "react";
import { useForm } from "react-hook-form";
import Btn from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import useLoader from "../../hooks/useLoader";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../AppContext/App.context";

import { themeColor } from "../../constants/themeColor";
import LoginEndpoints from "../Login/Login.endpoints";
import useLocalStorage from "../../hooks/localStorage";
import useApiService from "../../services/api.service";
import useToast from "../../components/Toast/hooks/useToast";
import { ValidationService } from "../../utils/Validation";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",

    "@media (max-width: 375px)": {
      width: "300px",
      height: "700px",
      marginLeft: "37px",
    },
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "300px",
    borderRadius: "10px",
    outline: "none",
  },
  btn: {
    margin: theme.spacing(1),
  },
  dropdown: {
    textAlign: "left",
  },
  switch: {
    float: "left",
    width: "50%",
    textAlign: "left",
    marginTop: "0.4rem",
    marginLeft: "0.5rem",

    "@media (max-width: 821px)": {
      width: "100%",
    },
  },
  submitBtn: {
    margin: "auto",
  },
}));

const SendOtpPopup = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["Common", "Supervisor"]);
  const [open, setOpen] = useState(false);
  const ApiService = useApiService();
  const toaster = useToast();
  const [otpState, setOtpState]: any = useState({
    updateBody: {},
  });
  let time: any = 31;
  const [showTimer, setShowTimer]: any = useState(false);
  const customData = useLocalStorage.getItem("CustomData");

  const [showResend, setShowResend]: any = useState(true);
  let [timer, setTimer]: any = useState(`00:${time}`);
  const [appData]: any = useAppContext();
  const access_token = appData.loginData.access_token;

  const loader: any = useLoader();

  const { register, setValue, handleSubmit, reset, errors } = useForm();

  useImperativeHandle(ref, () => ({
    handleOpen(row: any) {
      setOtpState({ ...otpState, updateBody: row });
      setOpen(true);
    },
    handleClose(value: boolean) {
      setOpen(false);
    },
  }));

  const obj = {
    otp: {
      required: ValidationService.requiredValidator(),
    },
  };

  const submitOTP = (data: any) => {
    let tempData = { ...otpState.updateBody, otp: data.OTP };
    props.updateDetails(tempData);
  };
  const sendOTP = () => {
    const request: any = {
      country_code: otpState.updateBody.country_code,
      phone_number: otpState.updateBody.phone,
      app_version: "100.1",
    };
    const headers = {
      access_token: appData.loginData.access_token,
      reference_id: useLocalStorage.getItem("referenceId"),
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };

    return ApiService.post(LoginEndpoints.otpValidation(reqData))

      .then(async (res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: t("LoginViaOTP:OTP Sent Successfully"),
          timeout: 3000,
          type: "success",
        });
        resendOTP();
      })
      .catch((err) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const handleClose = () => {
    setOpen(true);
  };

  const resendOTP = () => {
    // sendOTP()
    time = 31;
    let interval = setInterval(() => {
      if (time > 0) {
        setShowTimer(true);
        setShowResend(false);
        if (time <= 10) {
          setTimer(`00:0${--time}`);
        } else {
          setTimer(`00:${--time}`);
        }
      }
      if (time <= 0) {
        setShowTimer(false);
        setShowResend(true);
        clearInterval(interval);
      }
    }, 1000);
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography
              style={{
                fontSize: "18px",
                marginBottom: "12px",
                marginTop: "8px",
              }}
            >
              OTP Verification
            </Typography>
            <div className="SendOtp__card">
              <>
                <form onSubmit={handleSubmit(submitOTP)}>
                  <Input
                    type={"text"}
                    id="OTP"
                    name="OTP"
                    refValue={register(obj.otp)}
                    errors={errors}
                    onkeypress={() => {}}
                    label={t("Common:OTP")}
                    InputProps={{
                      inputProps: { maxLength: 6 },
                    }}
                  />
                  <div className="SendOtp__timer">
                    <div
                      className="d-flex justify-content-end Login__link"
                      onClick={() => sendOTP()}
                      style={{
                        cursor: showResend ? "pointer" : "none",
                        pointerEvents: showResend ? "auto" : "none",
                        color: showResend
                          ? `${themeColor.primary.main}`
                          : "#dddddd",
                      }}
                    >
                      {t("LoginViaOTP:Resend OTP?")}
                    </div>
                    {showTimer ? (
                      <div
                        style={{
                          color: `${themeColor.primary.main}`,
                          float: "right",
                          margin: "2px",
                        }}
                      >
                        {timer}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <Grid container justifyContent="center">
                    <Btn
                      type={"Submit"}
                      btnText={t("LoginViaOTP:Submit")}
                      className={classes.btn}
                    />
                    <Btn
                      onClick={() => {
                        setOpen(false);
                      }}
                      className={classes.btn}
                      color={"default"}
                      type={"button"}
                      btnText={t("Common:Cancel")}
                    ></Btn>
                  </Grid>
                </form>
              </>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
});

export default SendOtpPopup;
