import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateTimeFormatter from "../../utils/DateFormatter";
import useToast from "../Toast/hooks/useToast";
import "./DateTimePicker.scss";

const DateRangePicker = (props: any) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const toaster = useToast();
  const onChange = (dates: any) => {
    let [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (props.inline) {
      if (!end) {
        let range: any =
          DateTimeFormatter.getUTCStartAndEndTimeFromLocal(start);
        // end = new Date(range.endDate);
        end = new Date(range.endDate.replace(/-/g, "/"));
      }
      props.onChange(start, end);
    }
  };
  const handleCalendarClose = () => {
    let end: any = endDate;
    if (!endDate) {
      let range: any =
        DateTimeFormatter.getUTCStartAndEndTimeFromLocal(startDate);
      end = new Date(range.endDate);
      setEndDate(end);
    }
    if (props.maxRange) {
      let start: any = startDate;
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      console.log(start);
      // startDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(startDate);
      let endDT: any = end;

      endDT.setHours(23);
      endDT.setMinutes(59);
      endDT.setSeconds(59);
      console.log(endDT);
      // endDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(endDate);
      let range: any = { start, endDT };
      let RangeLimit: any = Math.round(
        (range["endDT"] - range["start"]) / (1000 * 60 * 60 * 24)
      );

      if (RangeLimit > props.maxRange) {
        toaster.addToast({
          message: `Please select range for less than ${
            props.maxRange ? props.maxRange : 7
          } days`,
          timeout: 3000,
          type: "error",
        });
        let s: any = new Date(props.start);
        let e: any = new Date(props.end);
        setStartDate(s);
        setEndDate(e);
        return;
      } else props.onChange(startDate, endDate);
    } else props.onChange(startDate, endDate);
  };

  useEffect(() => {
    //  setShown(true)
    if (props.start) {
      let start: any = new Date(props.start);
      setStartDate(start);
      let end: any = new Date(props.end);
      setEndDate(end);
    }
  }, [props.value]);

  return (
    <DatePicker
      className={props.className}
      //selected={startDate}
      onCalendarClose={
        props.inline
          ? () => {
              return false;
            }
          : handleCalendarClose
      }
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline={props.inline}
      dateFormat="yyyy-MM-dd"
    />
  );
};

export default DateRangePicker;
