import moment from "moment";
import useLocalStorage from "./localStorage";
export const defaultDateTimeFormat = "YYYY-MM-DD HH:mm";
export const defaultTimeForamt = "HH:mm";
export const defaultDateFormat = "YYYY-MM-DD";

export function convertDateTimeFormatToTimeDateString(dateTimeString: any) {
  const dateTime: String[] = dateTimeString.split(" ");
  return `${dateTime[1]} ${dateTime[0]}`;
}
const DateTimeFormatter = {
  // To get updated values from localstorage
  getDateTimeFormat() {
    return useLocalStorage.getItem("loginData").datetime_format
      ? `${useLocalStorage.getItem("loginData").datetime_format}`
      : "YYYY-MM-DD HH:mm";
  },
  convert12HoursFormatTo24Format(time: string) {
    return moment(time, "hh:mm A").format("HH:mm");
  },
  convertDateTimeToSpecificFormatInUtc(dateTime: Date, setRequestFormat?: any) {
    let format = setRequestFormat ? setRequestFormat : "YYYY-MM-DD HH:mm";
    // let format = 'YYYY-MM-DD HH:mm';
    return moment(dateTime).utc().format(format);
  },
  getDateFormat() {
    return useLocalStorage.getItem("loginData").date_format
      ? `${useLocalStorage.getItem("loginData").date_format}`
      : "YYYY-MM-DD";
  },
  getTimeFormat() {
    return useLocalStorage.getItem("loginData").time_format
      ? `${useLocalStorage.getItem("loginData").time_format}`
      : "HH:mm";
  },

  converDateTimeToSmall(type: any) {
    // Type 0: Time, 1: Date Time, 2: Date
    // if (type === 0 && props.showTimeSelectOnly) {
    //   if (format === "HH:mm") {
    //     return format;
    //   }
    //   currentFormat = currentFormat.toLowerCase();
    //   if (type === 0) {
    //     return currentFormat;
    //   }
    // } else if (type === 0) {
    //   let dateTime: any = DateTimeFormatter.getDateTimeFormat();
    //   dateTime = dateTime.split(" ");
    //   console.log(dateTime);
    //   if (dateTime[2] === "A") {
    //     return "hh:mm a";
    //   } else {
    //     return "HH:mm";
    //   }
    // }
    let currentFormat = this.getDateFormat();

    currentFormat = currentFormat.toLowerCase();
    if (type === 1 || type === 2) {
      if (type === 1) {
        currentFormat = currentFormat.split("h").join("H");
      }
      currentFormat = currentFormat.replace("m", "M");
      currentFormat = currentFormat.replace("m", "M");
      return currentFormat;
    }
  },
  // convertDateTimeToSpecificFormatInUtc(dateTime: Date, utcOffset?: string) {
  //   return moment(dateTime).utc().format(defaultDateTimeFormat);
  // },

  checkIfTimeInTz(dateTime: any) {
    if (dateTime && dateTime.includes("T")) {
      return true;
    } else {
      return false;
    }
  },
  convertDateTimeToSpecificFormat(
    dateTime: string,
    utcOffset?: any,
    format = defaultDateTimeFormat
  ) {
    format = this.getDateTimeFormat();
    if (dateTime.includes("T")) {
      if (utcOffset) {
        return moment.utc(dateTime).utcOffset(utcOffset).format(format);
      }
      return moment(dateTime).utc().format(format);
    } else {
      if (utcOffset) {
        return moment.utc(dateTime, format).utcOffset(utcOffset).format(format);
      }
      return moment(dateTime, format).utc().format(format);
    }
  },
  // changeOnlyFormatInSameTimeZone(dateTime: any, format?: string) {
  //   return moment(dateTime).format(format ? format : defaultDateTimeFormat);
  // },
  getTimzoneOffsetInHoursAndMinutes(timezoneOffset: number) {
    //eslint-disable-next-line
    let timezone_offset_min = timezoneOffset,
      offset_hrs: any = parseInt(Math.abs(timezone_offset_min / 60).toString()),
      offset_min: any = Math.abs(timezone_offset_min % 60),
      timezone_standard;

    if (offset_hrs < 10) offset_hrs = "0" + offset_hrs;

    if (offset_min < 10) offset_min = "0" + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0)
      timezone_standard = "-" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min > 0)
      timezone_standard = "+" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min == 0) timezone_standard = "Z";

    // Timezone difference in hours and minutes
    // String such as +5:30 or -6:00 or Z
    return timezone_standard;
  },
  // checkIfTimeInTz(dateTime: any) {
  //   if (dateTime.includes("T")) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // },
  changeOnlyFormatInSameTimeZone(dateTime: any, format?: string) {
    return moment(dateTime).format(format ? format : defaultDateTimeFormat);
  },

  getTimeFromDateTimeInSameTimeZone(
    dateTime: Date,
    format = defaultTimeForamt
  ) {
    return moment(dateTime).utc().format(format);
  },
  getValueInMilliSeconds(dateTime: any) {
    // console.log(moment(dateTime).utc());
    // console.log(moment(dateTime).utc().format(defaultDateTimeFormat));
    return moment(dateTime).valueOf();
  },
  getUTCStartAndEndTimeFromLocal(selectedDate: any) {
    let startDate = selectedDate;
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate = this.convertDateTimeToSpecificFormatInUtc(startDate);
    let endDate: any = selectedDate;
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate = this.convertDateTimeToSpecificFormatInUtc(endDate);
    return { startDate, endDate };
  },
  getUtcFromLocal(selectedDate: any) {
    return moment(selectedDate).local().format("YYYY-MM-DD HH:mm");
  },
  getLocalTimzoneOffsetInHoursAndMinutes() {
    //eslint-disable-next-line
    let timezone_offset_min = new Date().getTimezoneOffset(),
      offset_hrs: any = parseInt(Math.abs(timezone_offset_min / 60).toString()),
      offset_min: any = Math.abs(timezone_offset_min % 60),
      timezone_standard;

    if (offset_hrs < 10) offset_hrs = "0" + offset_hrs;

    if (offset_min < 10) offset_min = "0" + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0)
      timezone_standard = "+" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min > 0)
      timezone_standard = "-" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min == 0) timezone_standard = "Z";

    // Timezone difference in hours and minutes
    // String such as +5:30 or -6:00 or Z
    return timezone_standard;
  },
  isBefore(date: any) {
    return moment(date).isBefore(new Date());
  },
};
export default DateTimeFormatter;
