import React, { useState, Fragment, useEffect, useContext } from "react";
import clsx from "clsx";
import Header from "../Header/Header";
import "./HeaderSidebarWrapper.scss";

import smallLogo from "../../assets/images/inner-short-logo.jpeg";
import largeLogo from "../../assets/images/largeLogo.png";

import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

import TimelineIcon from "@material-ui/icons/Timeline";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import MapIcon from "@material-ui/icons/Map";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useLocation } from "react-router";
import headerDropdownService from "../../services/headerDropdown.service";
import SettingsIcon from "@material-ui/icons/Settings";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Icon } from "@material-ui/core";
import useLocalStorage from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppContext/App.context";

import { themeColor } from "../../constants/themeColor";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { SideDrawerContext } from "../../AppContext/Drawer.context";

const drawerWidth = 250;

const HeaderSidebarWrapper = (props: any) => {
  const { t, i18n } = useTranslation(["Sidebar"]);
  const { showSideDrawer, setShowSideDrawer } = useContext(SideDrawerContext);
  const { width } = useWindowDimensions();

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const customData = useLocalStorage.getItem("CustomData");

  const location = useLocation();
  const history = useHistory();
  const [appData]: any = useAppContext();

  const Permission: any = useLocalStorage.getItem("Permission");

  useEffect(() => {
    if (width) {
      if (width > 821) {
        setShowSideDrawer(false);
      }
    }
  }, [width]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBar: {
      boxShadow: "0 0 5px 0 #e5e9f2",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background: "#ffffff",
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth + 1}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    fakeMenuButton: {
      "&:hover, &:focus": {
        background: "none",
      },
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      color: themeColor.whiteColor,
    },
    drawerOpen: {
      width: showSideDrawer ? "0px" : drawerWidth,
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      background: "var(--sidebar-theme)",
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
      background: "var(--sidebar-theme)",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    largeLogo: {
      maxWidth: "200px",
      maxHeight: "50px",
      padding: "10px",
      // width: "calc(100% - 32px)",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    selected: {
      // height: "70px",
      "&.Mui-selected": {
        color: themeColor.whiteColor,
        borderImage: "var(--active-sidebar-border-image)",
        backgroundImage: "var(--active-sidebar-background-image)",
        width: "calc(100% - 10px)",
        padding: "10px",
        borderRadius: "50px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        borderLeft: "4px solid",
        "& svg": {
          color: themeColor.whiteColor,
        },
        // "&:hover, &:focus": {
        //   backgroundImage:
        //     "linear-gradient(to left, rgba(65,173,73,1) 0%, rgba(0,0,0,1) 101%)",
        //   color: "#fff",
        // },
      },
    },
    floatingIcon: {
      position: "absolute",
      bottom: "25px",
    },
    list: {
      height: "calc(100vh - 160px)",
      color: themeColor.whiteColor,
      "& .MuiListItemIcon-root": {
        minWidth: "44px",
      },
    },
    divider: {
      transition: "all .32s",
      outline: "none",
      background: "#3e3e3e",
      height: "1px",
      margin: "5px 0",
      width: "205px",
      marginLeft: "0px",
      padding: "0 !important",
      marginBottom: "10px",
    },
    subCategoryIcon: {
      marginLeft: "24px",
    },
    subCategoryList: {
      background: "var(--sidebar-theme)",
    },
    smallLogo: {
      marginLeft: "-10px",
      maxWidth: "35px",
      maxHeight: "35px",
    },
  }));
  const classes = useStyles();

  let APIKey: any = true;
  let PermissionsArr: any = Permission ? Object.values(Permission) : [];
  if (PermissionsArr.length) {
    PermissionsArr.map((val: any) => {
      if (!val) {
        APIKey = false;
        return;
      }
    });
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resetHeaderTeam = () => {
    const data = {
      type: "teamList",
      data: { teamId: "" },
    };
    headerDropdownService.emit(data);
  };

  const goToHome = () => {};
  const openCretaeTaskFromSidebar = () => {
    const dropdownData = {
      type: "clickedOnCreateTask",
      data: { clicked: true },
    };
    headerDropdownService.emit(dropdownData);
  };

  const getFavTextEl = () => {
    return document.getElementById("favtitle");
  };
  const getFaviconEl = () => {
    return document.getElementById("favicon");
  };
  // console.log()
  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={!showSideDrawer ? { marginLeft: "0px" } : { width: "100vw" }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={goToHome}
            edge="start"
            className={clsx(classes.menuButton, classes.fakeMenuButton, {
              [classes.hide]: open,
            })}
          >
            {/* <img style={{ width: "35px" }} src={smallLogo} /> */}
            <img
              // style={{ width: "35px" }}
              className={classes.smallLogo}
              src={
                customData && customData.navigation_icon
                  ? customData.navigation_icon
                  : smallLogo
              }
            />
          </IconButton>
          <Header></Header>
        </Toolbar>
      </AppBar>

      {/* ---------------------------- */}
      <div
        className="burger__menu_container"
        onClick={() => setShowSideDrawer(!showSideDrawer)}
        style={
          !showSideDrawer
            ? { left: "2%", top: "1.3%" }
            : { left: "1.5%", top: "1.3%" }
        }
      >
        <div className={`burger__menu`}>
          {showSideDrawer ? (
            <MenuIcon style={{ fontSize: "2.5rem", color: "#636363" }} />
          ) : (
            <CloseIcon style={{ fontSize: "2.5rem", color: "#fff" }} />
          )}
        </div>
      </div>
      <div className="sideDrawer__un__container">
        <Drawer
          variant="permanent"
          className={
            clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }) + " sidebar"
            // " mobileView_drawer"
          }
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          // style={showSideDrawer ? { width: "250px" } : { width: "0px" }}
        >
          <div className={classes.toolbar}>
            {/* <IconButton onClick={handleDrawerClose}>

            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
            <div>
              <img
                src={
                  customData && customData.inner_icon
                    ? customData.inner_icon
                    : largeLogo
                }
                className={classes.largeLogo}
              />
            </div>

            {/* <img src={largeLogo} className={classes.largeLogo} /> */}
            {/* <img
            style={{ position: "absolute", left: "210px" }}
            src={icon_to_close_sidebar}
            onClick={handleDrawerClose}
          /> */}
          </div>

          <div className="sidebar">
            <List className={classes.list}>
              <ListItem
                onClick={() => {
                  if (width) {
                    if (width < 821) {
                      setShowSideDrawer(true);
                    }
                  }
                  history.push("/jobs/list");
                }}
                selected={location.pathname.includes("/jobs/list")}
                className={classes.selected}
                button
                key="Jobs"
              >
                <ListItemIcon>
                  <AssignmentOutlinedIcon
                    color={"secondary"}
                  ></AssignmentOutlinedIcon>
                  {/* <img src={task_sidebar_icon} alt="task logo" /> */}
                </ListItemIcon>
                {open ? (
                  <ListItemText
                    className="sidebar-text"
                    primary={t("Sidebar:Jobs")}
                  />
                ) : (
                  ""
                )}
              </ListItem>

              {/* <ListItem
            onClick={() => {
              history.push("/jobs/create");
            }}
            selected={location.pathname.includes("/jobs/create")}
            className={classes.selected}
            button
            key="Jobs"
          >
            <ListItemIcon>
              <AddCircleOutlineIcon color={"action"} />
            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Create Job")} /> : ""}
          </ListItem> */}
              <ListItem
                onClick={() => {
                  if (width) {
                    if (width < 821) {
                      setShowSideDrawer(true);
                    }
                  }
                  history.push("/contacts");
                }}
                selected={location.pathname.includes("/contacts")}
                className={classes.selected}
                button
                key="Jobs"
              >
                <ListItemIcon>
                  <AccountBoxIcon color={"secondary"}></AccountBoxIcon>
                  {/* <img src={task_sidebar_icon} alt="task logo" /> */}
                </ListItemIcon>
                {open ? (
                  <ListItemText
                    className="sidebar-text"
                    primary={t("Sidebar:Contacts")}
                  />
                ) : (
                  ""
                )}
              </ListItem>
              <ListItem
                onClick={() => {
                  if (width) {
                    if (width < 821) {
                      setShowSideDrawer(true);
                    }
                  }
                  setTimeout(() => {
                    history.push("/analytics");
                  }, 100);
                }}
                selected={location.pathname.includes("/analytics")}
                className={classes.selected}
                button
                key="Analytics"
              >
                <ListItemIcon>
                  <TimelineIcon color={"secondary"} />
                </ListItemIcon>
                {open ? (
                  <ListItemText
                    className="sidebar-text"
                    primary={t("Sidebar:Analytics")}
                  />
                ) : (
                  ""
                )}
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    </Fragment>
  );
};
export default HeaderSidebarWrapper;
