export const preLoginRoutes = [
  "/login",
  "/login/",
  "/register",
  "/register/",
  "/step1",
  "/step1/",
  "/step2",
  "/step2/",
  "/resetpassword",
  "/resetpassword/",
  "/signup",
  "/signup/",
  "/checkotp",
  "/checkotp/",
];
