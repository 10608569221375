const EditProfileEndpoints = {
  updateProfile(reqData: any) {
    return {
      url: "customer/customer/update",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  deleteAccount(reqData: any) {
    return {
      url: "auth/user/delete",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};

export default EditProfileEndpoints;
