const LoginViaOtpEndpoints = {
  loginViaOTP(data: any) {
    return {
      url: "customer/customer/loginViaPhoneNumber",
      data: data["reqBody"],
      headers: data["headers"],
    };
  },
};
export default LoginViaOtpEndpoints;
