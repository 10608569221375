import React, { useState, useRef, useEffect } from "react";
import "./phone-picker.scss";
import { countriesData } from "./countries";
import { useClickOutside } from "../../hooks/clickOutside";
import useLocalStorage from "../../hooks/localStorage";
import { ErrorMessage } from "@hookform/error-message";
import cross from "../../assets/images/cross.svg";
import { Input } from "@material-ui/core";

// import tick from "../../assets/images/tick.svg";

const Phone: React.FC<any> = (props: any) => {
  const [showCountryCodes, setShowCountryCodes] = useState(false);
  const phone_number = useRef<any>("");
  let loginData: any = useLocalStorage.getItem("loginData");
  const defaultCountryObject = {
    dialCode: "1",
    countryCode: "us",
    country: "UNITED STATES",
  };
  const dial_code = useRef<any>("");
  const dropdownRef: any = useRef();

  const [selectedCountry, setSelectedCountry]: any = useState();
  const [countrySearchValue, setCountrySearchValue] = useState("");

  useEffect(() => {
    setCountryObject();
  }, []);
  useEffect(() => {
    setCountryObject(props.userCountry);
  }, [props.userCountry]);
  const setCountryObject = (countryCode?: any) => {
    if (countryCode) {
      let countryObj: any = countriesData.filter((data: any) => {
        return (
          data.countryCode === countryCode.toLowerCase() ||
          data.dialCode === countryCode
        );
      });
      if (!countryObj || !countryObj[0]) {
        countryObj = [defaultCountryObject];
      }
      setSelectedCountry(countryObj[0]);
      props.setDialCode(
        countryObj[0]?.dialCode || "1",
        countryObj[0].countryCode
      );
    } else {
      let country_code: any = loginData.country_code || "+1";
      let tempCode: any = country_code.slice(1, country_code.length);

      let countryObj: any = countriesData.filter((data: any) => {
        if (country_code === "+1") {
          return data.countryCode === "us";
        } else {
          return data.dialCode === tempCode;
        }
      });
      if (!countryObj || !countryObj[0]) {
        countryObj = [defaultCountryObject];
      }
      setSelectedCountry(countryObj[0]);
      props.setDialCode(
        countryObj[0]?.dialCode || "1",
        countryObj[0].countryCode
      );
    }
  };
  const onChangeHandler = (ev: any) => {
    if (!props.onchange) {
      return;
    }
    props.onchange(ev);
  };
  useEffect(() => {
    if (props.defaultPhone) {
      splitDefaultPhone();
    } else if (props.phoneNumber) {
      phone_number.current = props.phoneNumber;
    } else {
      if (selectedCountry) {
        props.setDialCode(
          selectedCountry.dialCode,
          selectedCountry.countryCode
        );
      }
    }
    // eslint-disable-next-line
  }, [props.defaultPhone]);

  const splitDefaultPhone = () => {
    let dialCode = "";
    if (props.defaultPhone.includes("+")) {
      dialCode = props.defaultPhone.substring(1);
    } else {
      dialCode = props.defaultPhone;
    }
    dial_code.current = countriesData.filter((country) => {
      if (dialCode === "1") {
        return country.countryCode === "us";
      } else {
        return dialCode === country.dialCode;
      }
    });
    if (dial_code?.current && dial_code?.current?.length > 0) {
      setSelectedCountry(dial_code?.current?.[0]);
      props.setDialCode(
        dial_code.current[0]?.dialCode,
        dial_code.current[0]?.countryCode
      );

      if (props.phoneNumber) {
        phone_number.current = props.phoneNumber;
      }
    }
  };

  useClickOutside(dropdownRef, () => {
    setShowCountryCodes(false);
  });

  const selectCountryHandler = (country: any) => {
    props.setDialCode(country.dialCode, country.countryCode);
    setSelectedCountry(country);
    setShowCountryCodes((prev) => !prev);
  };

  return (
    <React.Fragment>
      {selectedCountry && (
        <div className="phone">
          {props.hideLabel ? null : (
            <label className="phone__title py-2" style={props.labelStyle}>
              {props.label}
              {props.required ? <span className="required"> {"*"}</span> : null}
            </label>
          )}
          <div className="phone__wrapper">
            {props.showPhone ? (
              <React.Fragment>
                <div
                  className="phone__dropdown-btn"
                  onClick={() => {
                    if (props.disabled) {
                      return;
                    }
                    setShowCountryCodes((prev) => !prev);
                  }}
                  style={props.styleAddressForm?.dropdownBtn}
                >
                  <span
                    className={"flag flag-" + selectedCountry.countryCode}
                  ></span>
                  <p
                    style={{ margin: "0 0 0 5px", fontSize: "14px" }}
                  >{`+${selectedCountry.dialCode}`}</p>
                </div>
                {showCountryCodes ? (
                  <>
                    <ul ref={dropdownRef} className="phone__dropdown">
                      <Input
                        style={{
                          padding: "0.5rem",
                          border: "none",
                          outline: "none",
                        }}
                        placeholder="Search for a country...."
                        onChange={(e) =>
                          setCountrySearchValue(e?.target?.value?.toLowerCase())
                        }
                      />
                      {countriesData
                        .filter(
                          (item) =>
                            item.country
                              .toLowerCase()
                              .includes?.(countrySearchValue) ||
                            item.country.includes(countrySearchValue)
                        )
                        .map((country, index) => {
                          return (
                            <React.Fragment key={index}>
                              <li
                                className="phone__country-content"
                                onClick={() => selectCountryHandler(country)}
                              >
                                <span
                                  className={"flag flag-" + country.countryCode}
                                ></span>
                                <span className="phone__country-name">
                                  {country.country}
                                  <span className="phone__country-code">
                                    +{country.dialCode}
                                  </span>
                                </span>
                              </li>
                            </React.Fragment>
                          );
                        })}
                    </ul>
                  </>
                ) : null}
              </React.Fragment>
            ) : (
              <img className="input__icon" src={props.img} alt="icon img"></img>
            )}

            <input
              defaultValue={phone_number.current}
              ref={props.refValue}
              disabled={props.disabled ? props.disabled : false}
              placeholder={props.placeholder}
              value={props.value}
              onKeyPress={(evt: any) => {
                var theEvent: any = evt || window.event;

                // Handle paste
                // if (theEvent.type === 'paste') {
                //    // @ts-ignore
                //     key = window.clipboardData.getData('Text')
                // } else {
                // Handle key press
                var key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
                // }
                var regex = /[0-9]|\./;
                if (!regex.test(key)) {
                  theEvent.returnValue = false;
                  if (theEvent.preventDefault) theEvent.preventDefault();
                }
              }}
              name={props.name}
              className={
                props.errors
                  ? props.errors[props.name]
                    ? "phone__input-error"
                    : "phone__input"
                  : "phone__input"
              }
              onChange={onChangeHandler}
              style={props.styleAddressForm?.input}
            ></input>
            {
              props.errors && props.errors[props.name] ? (
                <span className="phone__cross">
                  <img src={cross} alt="cross"></img>
                </span>
              ) : null

              // props.showErrormsg ? null : (
              //   <span className="phone__cross">
              //     <img src={tick} alt="tick"></img>
              //   </span>
              // )
            }
          </div>
          {props.errors ? (
            <div className="phone__errormsg">
              <ErrorMessage
                errors={props.errors}
                name={props.name}
                render={({ message }) => <div>{message}</div>}
              />
            </div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default Phone;
