import io from "socket.io-client";
import { Subject } from "rxjs";
import useLocalStorage from "../hooks/localStorage";
import { taskHistoryTypes } from "../constants/taskHistoryTypes";

const subject = new Subject();
//
export const socketSubject = {
  subscribe: (update: any) => subject.subscribe(update),
  unsubscribe: () => subject.unsubscribe(),
  emit: (socketData: any) => {
    subject.next(socketData);
  },
  // subject.unsubscribe();
};
export const socketToasterMessageSubject = {
  subscribe: (update: any) => subject.subscribe(update),
  unsubscribe: () => subject.unsubscribe(),
  emit: (socketData: any) => {
    subject.next(socketData);
  },
  // subject.unsubscribe();
};
export const Socket = () => {
  // alert(2)
  let API_BASE_URL: any = process.env.REACT_APP_API_URL;

  const socket = io(API_BASE_URL, {
    transports: ["websocket"],
    query: "access_token=" + useLocalStorage.getItem("loginData").access_token,
    // path:"socket.io/?access_token="+useLocalStorage.getItem("loginData").access_token
  });

  socket.on("resourceDashboardTracking", (res: any) => {
    console.log(res, "resource tracking event");
    socketSubject.emit({ res: res, type: "resourceDashboardTracking" });
    // subject.next(res);
  });

  socket.on("reloadBrowser", (res: any) => {
    window.location.reload();
    // subject.next(res);
  });
  socket.on("dashboard", (res: any) => {
    console.log(res, "dashboard event");
    socketSubject.emit({ res: res, type: "dashboard" });
    showSocketToaster(res);
    // subject.next(res);
  });
  socket.on("resourceStatus", (res: any) => {
    console.log(res, "resourceStatus event");
    socketSubject.emit({ res: res, type: "resourceStatus" });
    // subject.next(res);
  });

  //   socket.emit("register_", {
  //     domain_name: "xyz.com",
  //     id: 3,
  //   });
};
const showSocketToaster = (res: any) => {
  showJobUpdationToaster(res);
};
const showJobUpdationToaster = (res: any) => {
  console.log(res);
  let toasterMessage = "Job ID: " + res.job_id + " ";
  if (res.task_id) {
    toasterMessage = "Task ID: " + res.task_id + " ";
  }

  toasterMessage = toasterMessage + taskHistoryTypes[res.type].comment;
  ShowToasterMessage(toasterMessage);
};
const ShowToasterMessage = (message: any) => {
  socketToasterMessageSubject.emit({
    message: message,
    type: "toasterMessage",
  });
  // const toastRight = useToastRight();
  // console.log(message);
  // toastRight.addToastRight({
  //   message: message,
  //   timeout: 3000,
  //   type: "success",
  // });
};
