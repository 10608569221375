import React, { useEffect, useState, Fragment, useRef } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CommonEndpoints from "../../services/commonApi.service";
import useApiService from "../../services/api.service";
import { useAppContext } from "../../AppContext/App.context";
import headerDropdownService from "../../services/headerDropdown.service";
import ResourceDropdownIcon from "../../assets/images/header/resource_dropdown_icon.svg";
import classes from "../../assets/css/HeaderDropdown.module.scss";

import { useTranslation } from "react-i18next";

const ResourceListDropdownForHeader = (props: any) => {
  const [resourceList, setResourceList]: any = useState([]);

  const { t, i18n } = useTranslation(["Header"]);

  const [selectResourceId, setResourceId] = useState("");
  const [appData]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const ApiService = useApiService();

  const onResourceChange = (event: any) => {
    setResourceId(event.target.value);
    const dropdownData = {
      type: "resourceList",
      data: { resourceId: event.target.value },
    };
    headerDropdownService.emit(dropdownData);
  };
  const getResourceList = async () => {
    // return;
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      limit: 1000,
      get_user_details: true,
      is_active: true,
      skip: 0,
    };
    if (props.teamId) {
      qureyParams.team_id = props.teamId;
    }
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getResourceList(reqData)
    );
    if (res.data.data.length) {
      setResourceListDropdown(res.data.data);
    } else setResourceList([]);
  };
  useEffect(() => {
    //  setShown(true)
    console.log(props.teamId);
    getResourceList();
  }, [props.teamId]);
  const setResourceListDropdown = (data: any) => {
    let arr: any = data;
    arr.unshift({ resource_id: 0, name: t("Header:All Resources") });
    setResourceList(arr);
    setResourceId("0");
  };
  const renderResourceList = () => {
    return (
      resourceList.length > 0 &&
      resourceList.map((resource: any, index: any) => {
        return (
          <MenuItem value={resource.resource_id}> {resource.name}</MenuItem>
        );
      })
    );
  };
  return (
    <Fragment>
      {resourceList.length ? (
        <div>
          <img
            src={ResourceDropdownIcon}
            alt="team drpdown"
            className={classes["dropdown-pre-icon"]}
          ></img>
          <Select
            disableUnderline={true}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectResourceId}
            onChange={onResourceChange}
          >
            {renderResourceList()}
          </Select>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ResourceListDropdownForHeader;
