// import { loginViaAccessToken } from "./AppContext/actions/actions";

const appEndpoints = {
  loginViaAccessToken: (data: any) => {
    return {
      url: "user_login_via_access_token",
      data,
    };
  },

  get_domain_detail: (data: any) => {
    return {
      url: "get_domain_detail",
      data,
    };
  },
  get_dta: (data: any) => {
    return {
      url: "themes/getTemplate",
      data,
    };
  },
  formSettings(data: object) {
    return {
      url: "customer/formSettings/get",
      data,
    };
  },
};

export default appEndpoints;
